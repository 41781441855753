import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { media } from 'resources'

import { removeSpace } from 'resources/helpers/format-string'

export const PlanCard = ({
  primaryColor,
  blackDays,
  noMarginMd,
  plan,
  planCount,
  utm,
}) => {
  const listOfFeatures = plan.description ? plan.description.split(/\r?\n/) : []
  const planUrl = `${process.env.GATSBY_CHECKOUT_URL}?plano=${plan.id}&${utm ? utm.replace(/\?/g, '') : plan.utm
    }`

  const planFormattedPrice = (value) => {
    if (!value) {
      return ''
    }

    const priceSplittedByCents = value.toFixed(2).toString().split('.')
    const priceMainValue = priceSplittedByCents[0]
    const priceCentsValue = priceSplittedByCents[1] || '00'

    return (
      <>
        <NeonText isBlackDays={blackDays} isBestOption={plan.bestOption}>R$ {priceMainValue}</NeonText>
        <PlanCents hasBlueBackground={hasBlueBackground} isBlackDays={blackDays} isBestOption={plan.bestOption}>
          ,{priceCentsValue}
        </PlanCents>
        {blackDays && plan.bestOption && <NeonText isBlackDays={blackDays} isBestOption={plan.bestOption} lower>/mês (hoje)</NeonText>}
      </>
    )
  }

  const getFormattedCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)
  }

  const renderPlanInstallmentInfo = () => {
    if (plan.numberOfInstallments === 1) {
      return (
        <PlanInstallmentInfo
          hasBlueBackground={hasBlueBackground}
          isBlackDays={blackDays}
          isBestOption={plan.bestOption}
        >
          <span>
            1x no cartão, boleto{!plan.valueWithPixDiscount && ', ou PIX'}
          </span>
          {plan.valueWithPixDiscount && (
            <span>
              ou {getFormattedCurrency(plan.valueWithPixDiscount)} à vista (no
              PIX)
            </span>
          )}

        </PlanInstallmentInfo>
      )
    }

    return (
      <PlanInstallmentInfo
        hasBlueBackground={hasBlueBackground}
        isBlackDays={blackDays}
        isBestOption={plan.bestOption}
      >
        <span>{plan.numberOfInstallments}x sem juros no cartão</span>
        <span>
          {getFormattedCurrency(plan.totalValue)} à vista (cartão, boleto
          {!plan.valueWithPixDiscount && ', no PIX'})
        </span>
        {plan.valueWithPixDiscount && (
          <span>
            {getFormattedCurrency(plan.valueWithPixDiscount)} à vista (no PIX)
          </span>
        )}
      </PlanInstallmentInfo>
    )
  }

  const hasBlueBackground = planCount > 2 && !plan.bestOption

  const renderPlanTitle = (accessTimeInMonths) => {
    if (accessTimeInMonths > 12) {
      return (
        <PlanTitle isBlackDays={blackDays}>
          12 MESES DE ACESSO + {accessTimeInMonths - 12}{' '}
          {accessTimeInMonths - 12 === 1 ? 'MÊS' : 'MESES'} DE BÔNUS
        </PlanTitle>
      )
    }
    if (accessTimeInMonths === 2) {
      return (
        <PlanTitle isBlackDays={blackDays}>
          1 MÊS DE ACESSO + 1 MÊS DE BÔNUS
        </PlanTitle>
      )
    }
  }

  return (
    <Wrapper
      primaryColor={primaryColor}
      isBlackDays={blackDays}
      noMarginMd={noMarginMd}
      isBestOption={plan.bestOption}
      planCount={planCount}
      hasBlueBackground={hasBlueBackground}
    >

      <Title
        isBestOption={plan.bestOption}
        hasBlueBackground={hasBlueBackground}
        isBlackDays={blackDays}
      >
        {blackDays
          ? (
            <WrapperTitle isBlackDays={blackDays} isBestOption={plan.bestOption}>
              <h2>
                {blackDays && plan.bestOption ? 'OFERTA DE BLACK FRIDAY POR TEMPO LIMITADO!' : plan.accessTimeInMonths === 1
                  ? '1 MÊS DE ACESSO'
                  : `${plan.accessTimeInMonths} MESES DE ACESSO`}
              </h2>

            </WrapperTitle>
          ) : (
            <h3>
              {plan.accessTimeInMonths === 1
                ? '1 MÊS DE ACESSO'
                : `${plan.accessTimeInMonths} MESES DE ACESSO`}
            </h3>
          )}

        {!blackDays && renderPlanTitle(plan.accessTimeInMonths)}
        <PlanSubtitle
          hasBlueBackground={hasBlueBackground}
          isBestOption={plan.bestOption}
          isBlackDays={blackDays}
        >
          {!blackDays && (
            <>
              COM CORREÇÃO DE REDAÇÃO
              <br />
              <br />
            </>
          )}
          {blackDays && plan.bestOption && (
            <WrapperDescription>
              <s>Normalmente R$49,00/mês</s>
            </WrapperDescription>
          )}
        </PlanSubtitle>

      </Title>
      {!blackDays && plan.bestOption && <Ribbon isBlackDays={blackDays}>Recomendado</Ribbon>}
      <Box isBlackDays={blackDays} isBestOption={plan.bestOption}>
        <PlanPriceWrapper
          hasBlueBackground={hasBlueBackground}
          isBestOption={plan.bestOption}
          isBlackDays={blackDays}
        >
          <PlanPrice hasBlueBackground={hasBlueBackground}>
            {planFormattedPrice(plan.valueOfInstallment)}
          </PlanPrice>

          {renderPlanInstallmentInfo()}
          {blackDays && plan.bestOption && <EconomyText>Economize 25%</EconomyText>}
        </PlanPriceWrapper>
        {blackDays &&
          <Title
            isBestOption={plan.bestOption}
            hasBlueBackground={hasBlueBackground}
            isBlackDays={blackDays}
          >
            {plan.bestOption ? (
              <>
                <span>13 meses de acesso <br /> com correção de redação.</span>
                <p>(12 meses + 1 mês bônus)</p>
              </>
            )
              : (
                <>
                  <span style={{ marginTop: 10 }}>1 mês de acesso <br /> com correção de redação.</span>
                </>
              )}
            <span style={{ marginTop: 5 }}>Garantia de reembolso <br />de 7 dias.</span>
            {/* {renderPlanTitle(plan.accessTimeInMonths)}
            <PlanSubtitle
              hasBlueBackground={hasBlueBackground}
              isBestOption={plan.bestOption}
              isBlackDays={blackDays}
            >
              COM CORREÇÃO DE REDAÇÃO
            </PlanSubtitle> */}
          </Title>}
        {listOfFeatures.length > 0 && (
          <List>
            {listOfFeatures.map((item, index) => (
              <ListItem
                key={index}
                dangerouslySetInnerHTML={{ __html: item }}
                hasBlueBackground={hasBlueBackground}
                isBlackDays={blackDays}
                isBestOption={plan.bestOption}
              />
            ))}
          </List>
        )}

        {plan.bestOption ? (
          <RecommendedPlanSubscribeButton
            href={`${planUrl}&field_action=${removeSpace(plan.name)}`}
            hasBlueBackground={hasBlueBackground}
            isBlackDays={blackDays}
          >
            QUERO ESTE
          </RecommendedPlanSubscribeButton>
        ) : (
          <NormalPlanSubscribeButton
            href={`${planUrl}&field_action=${removeSpace(plan.name)}`}
            hasBlueBackground={hasBlueBackground}
            isBlackDays={blackDays}
          >
            QUERO ESTE
          </NormalPlanSubscribeButton>
        )}
      </Box>
    </Wrapper>
  )
}

PlanCard.propTypes = {
  primaryColor: t.string,
  blackDays: t.bool,
  noMarginMd: t.bool,
  plan: t.object,
  planCount: t.number,
  utm: t.string,
}

const PlanTitle = styled.span`
  background: ${({ theme, isBlackDays }) =>
  isBlackDays ? theme.blackFridayTheme.background : theme.colors.primary};
  padding: 4px;
  border-radius: 4px;
  font-weight: 900;
  font-size: 1rem;
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  /* padding: 3rem 2.5rem; */
  ${({ isBlackDays, isBestOption }) => !isBlackDays && 'padding: 3rem 2.5rem;'}
  background: ${({
    theme,
    primaryColor,
    isBlackDays,
    hasBlueBackground,
    isBestOption,
  }) => {
    if (isBlackDays) {
      return isBestOption
        ? theme.blackFridayTheme.background
        : theme.blackFridayTheme.background
    }

    if (hasBlueBackground) {
      return 'linear-gradient(180deg, rgba(50,64,126,1) 0%, rgba(0,17,94,1) 100%);'
    }

  return theme.colors.background
  }};

  border: 2px solid
    ${({ theme, hasBlueBackground, isBlackDays, isBestOption }) => {
    if (isBlackDays) {
      return theme.blackFridayTheme.textLight
    }
    if (hasBlueBackground) {
      return theme.colors.muted
    }
    return 'none'
  }};

  box-shadow: ${({ theme, isBlackDays, isBestOption }) =>
    isBlackDays && isBestOption
      ? `0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon}`
      : 'none'};

  border-radius: ${({ theme }) => theme.radius.lg};

  height: 0%;
  max-width: 34rem;

  order: ${({ isBestOption, planCount }) =>
    isBestOption && planCount >= 3 ? 1 : 0};

  transform: ${({ isBestOption }) =>
    isBestOption ? 'scale(1.05)' : 'scale(1)'};

  ${({ noMarginMd }) =>
    noMarginMd &&
    media.between('sm', 'lg')`
    margin-right: 0;
  `}

  &:last-child {
    order: 2;
  }

  ${media.lessThan('md')`
    max-width: 70%;
    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: ${({ isBestOption }) => (isBestOption ? '5rem' : '3rem')};
    }
  `}

  ${media.lessThan('sm')`
    margin: 0 .5rem;
    max-width: 100%;
  `}

  &:not(:first-child) {
      margin-left: 10px;
    }
`

const Ribbon = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0.75rem 0;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme, isBlackDays }) =>
    isBlackDays ? theme.blackFridayTheme.primary : theme.colors.primary};

  background: rgb(0, 235, 169);
  background: ${({ theme, isBlackDays }) => {
    if (isBlackDays) {
      return theme.blackFridayTheme.bodyBackground
    }

    return `
      radial-gradient(
        circle,
        rgba(0, 235, 169, 1) 0%,
        rgba(0, 232, 170, 1) 26%,
        rgba(0, 153, 213, 1) 99%,
        rgba(0, 73, 255, 1) 100%
      )
    `
  }};
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ isBlackDays }) => isBlackDays ? '1.5rem;' : '0;'};
  gap: 1rem;
  text-align: center;

  > * {
    color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
      if (isBlackDays) {
        return isBestOption
          ? theme.blackFridayTheme.textLight
          : theme.blackFridayTheme.text
      }

      return hasBlueBackground ? 'white' : theme.colors.primary
    }};
  }

  > h4 {
    font-size: 2.4rem;
  }

  > h3 {
    font-size: 2.8rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  > h2 {
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  > span {
  color: ${({ theme, isBlackDays }) =>
    isBlackDays ? theme.blackFridayTheme.textLight : theme.colors.background};
  ${({ theme, isBlackDays }) => isBlackDays && 'font-size: 17px;'}
  }

  > p {
    font-size: 11px;
    margin-top: -10px;
  }
`

const PlanSubtitle = styled.div`
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
    if (isBlackDays) {
      return theme.blackFridayTheme.textLight
    }

    return hasBlueBackground ? theme.colors.accent : theme.colors.primary
  }};
`

const PlanPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 0.25rem;
  margin-top: ${({ isBestOption, isBlackDays }) => (isBlackDays ? isBestOption ? '0rem' : '-35px' : '0')};

  > * {
    color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
      if (isBlackDays) {
        return isBestOption
          ? theme.blackFridayTheme.textLight
          : theme.blackFridayTheme.text
      }

      return hasBlueBackground ? 'white' : theme.colors.primary
    }};
  }
`

const PlanPrice = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
  font-size: 56px;

  ${media.lessThan('xsm')`
    font-size: 45px;
  `}
`

const PlanCents = styled.div`
  font: normal normal 900 30px/70px Gilroy;
  letter-spacing: 0px;
  line-height: normal;
  text-shadow: ${({ theme, isBlackDays, isBestOption }) =>
    isBlackDays && isBestOption
      ? `0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon}`
      : 'none'};
`

const PlanInstallmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-weight: bold;

  color: ${({ theme, isBlackDays, hasBlueBackground, isBestOption }) => {
    if (isBlackDays) {
      return theme.blackFridayTheme.textLight
    }

    return hasBlueBackground ? theme.colors.accent : theme.colors.primary
  }};
`

const List = styled.ul`
  padding: 2rem 0 0 0;
  list-style: none;
`

const ListItem = styled.li`
  position: relative;
  padding-left: 2.5rem;
  font-size: 1.2rem;

  color: ${({ theme, isBlackDays, isBestOption, hasBlueBackground }) => {
    if (isBlackDays) {
      return isBestOption
        ? theme.blackFridayTheme.textLight
        : theme.blackFridayTheme.text
    }

    return hasBlueBackground ? 'white' : theme.colors.third
  }};

  &:not(:last-child) {
    padding-bottom: 1.8rem;
  }

  &:before {
    content: ${({ hasBlueBackground, isBlackDays, isBestOption }) => {
      if (isBlackDays) {
        return 'url("ic-check-green.svg")'
      }

      return hasBlueBackground
        ? 'url("ic-check-green.svg")'
        : 'url("ic-check-blue.svg")'
    }};
    position: absolute;
    left: 0;
    top: -0.2rem;
  }
`

const RecommendedPlanSubscribeButton = styled.a`
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  border-radius: 38px;
  padding: 2rem 0;
  margin-top: 2rem;
  font: normal normal 800 20px/25px Gilroy;
  letter-spacing: 1.6px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 200ms;

  color: ${({ theme, isBlackDays }) =>
  isBlackDays ? theme.colors.primary : theme.colors.primary};

  /* background-color: ${({ theme, isBlackDays }) =>
    isBlackDays ? theme.blackFridayTheme.textRed : theme.colors.accent}; */
  background-color: ${({ theme }) => theme.colors.accent};
  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`

const NormalPlanSubscribeButton = styled.a`
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  padding: 2rem 0;
  margin-top: 2rem;
  font: normal normal 800 20px/25px Gilroy;
  letter-spacing: 1.6px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 200ms;

  border-radius: 38px;
  border: 2px solid
    ${({ theme, hasBlueBackground, isBlackDays }) => {
      if (isBlackDays) {
        return theme.blackFridayTheme.textLight
      }

      return hasBlueBackground ? 'white' : theme.colors.primary
    }};

  background: ${({ hasBlueBackground, isBlackDays, theme }) => {
    if (isBlackDays) {
      return theme.blackFridayTheme.background
    }
    return hasBlueBackground ? 'transparent' : '#f7f7f7'
  }};

  color: ${({ theme, hasBlueBackground, isBlackDays }) => {
    if (isBlackDays) {
      return theme.blackFridayTheme.textLight
    }
    return hasBlueBackground ? 'white' : theme.colors.primary
  }};

  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`

const WrapperTitle = styled.div`
  background: ${({ theme, isBlackDays, isBestOption }) => isBlackDays ? isBestOption ? `linear-gradient(to bottom, ${theme.colors.primary}, ${theme.blackFridayTheme.neon})` : theme.blackFridayTheme.background : theme.colors.background};
  padding: 3rem 2.5rem;
  width: 100%;
  font-size: 15px;
  ${({ isBlackDays, isBestOption }) => isBlackDays && 'font-size: 15px;'}
  border-bottom: 2px solid
    ${({ theme, hasBlueBackground, isBlackDays, isBestOption }) => {
    if (isBlackDays) {
      return isBestOption ? theme.blackFridayTheme.textLight : 'none'
    }
    if (hasBlueBackground) {
      return theme.colors.muted
    }
    return 'none'
  }};

  box-shadow: ${({ theme, isBlackDays, isBestOption }) =>
    isBlackDays && isBestOption
      ? `0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon}`
      : 'none'};
`

const Box = styled.div`
  padding:  ${({ isBlackDays, isBestOption }) => isBestOption ? isBlackDays ? '0 2.5rem 3rem 2.5rem;' : '3rem 2.5rem 3rem 2.5rem;' : '0 2.5rem 3rem 2.5rem;'};
`

const NeonText = styled.div`
  text-shadow: ${({ theme, isBlackDays, isBestOption }) =>
    isBlackDays && isBestOption
      ? `0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon}`
      : 'none'};

  ${({ lower }) => lower && `
    font-size: 15px;
    position: absolute;
    left: 67%;
    top: 25.8%;`
  }

${media.lessThan('xxxsm')`
  ${({ lower }) => lower && `
    font-size: 9px;
    position: absolute;
    left: 68%;
    top: 12.5%;`
    }
  `}

${media.between('xxxsm', 'xxsm')`
  ${({ lower }) => lower && `
    font-size: 9px;
    position: absolute;
    left: 65%;
    top: 14.3%;`
    }
  `}

  ${media.between('xxsm', 'xsm')`
    ${({ lower }) => lower && `
      font-size: 10px;
      position: absolute;
      left: 66%;
      top: 14.4%;`
    }
  `}

  ${media.between('xsm', 'sm')`
    ${({ lower }) => lower && `
      font-size: 15px;
      position: absolute;
      left: 67%;
      top: 13.4%;
`
    }
  `}

  ${media.between('smd', 'md')`
    ${({ lower }) => lower && `
      font-size: 15px;
      position: absolute;
      left: 63%;
      top: 15.2%;`
    }
  `}
  ${media.greaterThan('md')`
  ${({ lower }) => lower && `
    font-size: 15px;
    position: absolute;
    left: 67%;
    top: 13%;`
    }
  `}

`
const EconomyText = styled.div`
  color: #ff65ff;
  font-size: 20px;
  align-self: center;
  margin: 10px;
`
const WrapperDescription = styled.div`
  margin-top: 10px;
`
